@import '../../styles/variables/colors';

.avaibility-calendar-screen {
  .ant-picker-calendar-full .ant-picker-panel .ant-picker-calendar-date-value {
    text-align: start;
    font-size: 16px;
    color: #25282b;
  }
  .ant-picker-calendar-full .ant-picker-panel .ant-picker-calendar-date {
    margin: 0px !important;
    // border: 1px solid #e6e6e6 !important;
    // overflow-wrap: normal !important;
    // background: #fcfcfc;
  }
  .leave-indication {
    background: $theme-warning !important;
  }
  .less-5-appointments-indication {
    background-color: #a7a7a7 !important;
  }
  .less-10-appointments-indication {
    background-color: $theme-green !important;
  }
  .more-10-appointments-indication {
    background-color: $theme-primary !important;
  }
  tr {
    text-align: center;
    th {
      padding: 10px !important;
      font-size: 18px !important;
      color: $gray-6 !important;
    }
  }
  .calendar-month {
    margin-top: 10px;
    width: 100%;
    text-align: center;
    padding: 10px;
    background-color: $theme-primary;
    color: white;
    font-size: 21px;
    border-radius: 8px 8px 0px 0px;
  }

  .ant-picker-calendar {
    background: none !important;
  }
  .ant-picker-calendar .ant-picker-panel .ant-picker-body {
    padding: 0px 40px 40px 40px !important;
  }

  .calendar-success {
    background-color: rgba(#27ae60, 0.1);
    border-top: 1px solid #27ae60;
    color: #27ae60;
  }

  .calendar-warning {
    background-color: rgba(245, 158, 11, 0.1);
    color: #b45309;
    border-top: 1px solid #b45309;
  }
  .calendar-less-5 {
    background-color: rgba(167, 167, 167, 0.1) !important;
    border-top: 1px solid $gray-7 !important;
    color: #a7a7a7 !important;
  }
  .calendar-less-10 {
    background-color: rgba($theme-green, 0.1) !important;
    border-top: 1px solid $theme-green !important;
    color: $theme-green !important;
  }
  .calendar-more-10 {
    background-color: rgba($theme-primary, 0.1) !important;
    border-top: 2px solid $theme-primary !important;
    color: $theme-primary !important;
  }
  .calendar-no-slots {
    background: #fcfcfc;
  }
  .ant-badge-status-text {
    font-size: 14px !important;
    overflow-wrap: normal !important;
  }
}
