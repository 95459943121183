.register-doctor-screen {
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.register-doctor-form {
  align-items: center;
  margin: 70px 0px;
  padding: 40px 100px;
  background: white;
  border-radius: 8px;

  .ant-col {
    width: 400px;
  }
}
