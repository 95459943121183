@import '../../../styles/variables/colors';
@import '../../../styles/variables/mixins';

.profile-drop {
  div {
    cursor: pointer;
  }
  div:hover {
    background: $theme-light-purple;
  }
  .profile-link {
    padding: 12px 24px;
  }
}

.ant-popover-inner-content {
  padding: 0px !important   ;
}
.mobile-toggle-button {
  display: none;
  padding: 0;
  border: none;
  background: 0;

  &:hover {
    color: $black !important;
  }

  .anticon {
    font-size: 16px;
  }

  @include mobile {
    display: flex;
    align-items: center;
  }

  @include tablet {
    display: flex;
    align-items: center;
  }
}