@import '../../../../styles/variables/colors';

.doctor-details {
  background-color: white;
  padding: 42px !important;
  border-radius: 4px;
  .doctor-image {
    text-align: center;
    margin-bottom: 20px;
  }
  .label {
    font-size: 12px;
    color: $theme-grey-font;
    margin-top: 20px;
  }
  .entry {
    font-size: 15px;
    margin-top: 6px;
  }
  .info-circle {
    height: 12px;
  }
  .feature-label {
    display: flex;
    align-items: center;
  }
  .tool-tip {
    margin-left: 10px;
  }
}
