@import '../../../styles/variables/colors';

.medical-history {
  .ant-input-search > .ant-input-group > .ant-input-group-addon:last-child {
    visibility: hidden;
  }

  .ant-input {
    margin-left: 4px;
    background: transparent;
  }
  .ant-input-affix-wrapper {
    padding: 4px 11px;
    border: none;
    background-color: $theme-dark-grey-background;
    margin-bottom: 20px;
  }

  .drawer-content {
    border-radius: 8px;
    background-color: white;
    padding: 30px;
  }

  .ant-collapse {
    margin-bottom: 20px;
  }
  .ant-collapse-header {
    background-color: white;
  }

  .panel-header {
    .blue-highlight {
      font-size: 12px;
      color: $theme-purple;
      letter-spacing: 1px;
      text-transform: uppercase;
    }
  }

  .panel-content {
    margin: 20px;
    .ant-input {
      width: 80%;
    }
    .label {
      margin-bottom: 10px;
    }
    .small-label {
      margin-bottom: 5px;
      font-size: 12px;
    }

    .reports {
      margin-bottom: 20px;
    }
    .report-content {
      width: 90%;
      padding: 8px;
      border-radius: 6px;
      border: 1px solid $theme-grey-border;
      display: flex;
      align-items: center;
    }
  }

  .attachment-title {
    margin: 20px 0px;
  }
  .ant-image {
    margin-right: 10px;
  }
  .ant-image-img {
    border-radius: 5px;
  }
  .ant-image-mask {
    border-radius: 5px;
  }
}
