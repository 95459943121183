@import "../../styles/variables/colors";

.form-item {
  margin-bottom: 20px;
  margin-left: 12px;
}
.contact-label {
  margin-right: 14px;
}
fieldset {
  background-color: #eeeeee;
}
legend {
  background-color: gray;
  color: white;
  padding: 5px 10px;
}
input {
  margin: 5px;
  padding:2px;
}
button{
    background-color: green;
    color: white;
}
