.delete-button {
  background-color: red;
  color: white;
  cursor: pointer;
}
.view-button {
  margin-left: 8px;
  background-color: blue;
  color: white;
  cursor: pointer;
}
