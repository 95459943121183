.availability-slots {
  .slots {
    display: flex;
    overflow-x: auto;
    margin: 10px 0px;
    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none; /* Internet Explorer 10+ */
    scroll-behavior: smooth;
  }

  .slots::-webkit-scrollbar {
    /* WebKit */
    width: 0;
    height: 0;
  }

  .single-slot {
    border: 1px solid #cecece;
    border-radius: 4px;
    padding: 4px 0px;
    text-align: center;
    min-width: 56px;
    margin-right: 16px;
  }
  .row {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}
