@import '../../styles/variables/colors';
@import '../../styles/variables/mixins';

.dashboard-auth-container {
 // padding: 80px 140px;
  background-color: $theme-grey-background;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;

  @include mobile-tablet-p {
    padding: 20px;
  }

  .dashboard-inner-container {
    padding: 30px;
    background: white;
    border-radius: 8px;
    max-width: 1200px;
    max-height: 600px;
    @include mobile-tablet-p{
      max-height: 900px;
    }

    .login-img {
      img {
        @include mobile-tablet-p {
          width: 100%;
        }
      }
    }
  }

  .form {
    padding: 0px 60px;

    @include mobile-tablet-p {
      padding: 0;
    }
    .ant-form-item{
      margin-bottom: 24px;
    }
  }

  .title {
    font-size: 32px;
    font-weight: 700;
   // margin-bottom: 24px;
    min-width: 300px;

    @include mobile-tablet-p {
      margin-top: 20px;
      font-size: 24px;
      min-width: none;
    }
  }

  .subtitle {
    font-size: 16px;
    margin-top: -20px;
    margin-bottom: 24px;
    font-weight: 400;
    color: $textColor;
  }

  .purple-info {
    color: $theme-purple;
    font-size: 16px;
    font-weight: 500;
  }

  .grey-info {
    color: $textColor;
    font-size: 16px;
    font-family: 400;
  }
  .recover-btn{
    background-color:#34C98166 !important;
    border: none;

    &:hover{
      background-color:#34C98180 !important;
    }

    ;
  }
}