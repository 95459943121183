@import '../../styles/variables/colors';
@import '../../styles/variables/mixins';

.unbilled-card {
  background: #ffffff;
  border: 1px solid #ebeaed;
  border-radius: 5px;
  margin-bottom: 50px;

  .info {
    padding: 50px;
    text-align: center;
  }

  .appointements {
    font-size: 16px;
    color: $theme-dark;

    .divider-inner {
      display: block;
      width: 70%;
      height: 1px;
      background: $theme-dark;
    }
  }

  .price-title {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    font-size: 20px;
    font-weight: 600;

    span {
      color: $theme-dark;
      font-size: 45px;
      margin-top: -10px;
    }
  }
  .divider {
    width: 100%;
    border: 1px solid #e4daff;
    margin: 0px;
  }
}
