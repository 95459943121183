@import '../../../styles/variables/colors';
@import '../../../styles/variables/mixins';

.billing-componenet {
  padding: 20px 5px;

  // select-custom
  .select-custom {
    .ant-select .ant-select-selector {
      height: 45px;
      align-items: center;
    }
    .label {
      margin-bottom: 10px;
      color: $theme-dark;
      font-size: 16px;
    }
    .ant-select-multiple .ant-select-selection-item {
      background: rgba($theme-primary, 0.1);
    }
  }

  .title {
    font-size: 24px;
    font-weight: 700;
    color: $theme-dark;
    margin: 50px 0px;
  }

  .unbilled-card {
    background: #ffffff;
    border: 1px solid #ebeaed;
    border-radius: 5px;
    margin-bottom: 50px;
    max-width: 25%;
    margin-right: 50px;

    .tag {
      background: url('../../../assets/icons/badge.svg');
      background-repeat: no-repeat;
      padding: 10px;
      color: #ffa827;
      font-size: 14px;
      font-weight: 600;
      margin-top: 20px;
      background-size: contain;
    }

    .info {
      padding: 50px;
      text-align: center;
    }

    .appointements {
      font-size: 16px;
      color: $theme-dark;

      .divider-inner {
        display: block;
        width: 70%;
        height: 1px;
        background: $theme-dark;
      }
    }

    .price-title {
      display: flex;
      align-items: flex-start;
      justify-content: center;
      font-size: 20px;
      font-weight: 600;

      span {
        color: $theme-dark;
        font-size: 45px;
        margin-top: -10px;
      }
    }
  }

  .previous-billing-card {
    background: #ffffff;
    border: 1px solid #ebeaed;
    border-radius: 5px;
    margin-bottom: 30px;

    .download {
      font-size: 24px;
      color: $theme-dark;

      .anticon {
        margin-left: 10px;

        svg {
          fill: #a7a7a7;
        }
      }
    }

    .tag {
      background: url('../../../assets/icons/badge-green.svg');
      background-repeat: no-repeat;
      padding: 10px;
      color: #27ae60;
      font-size: 14px;
      font-weight: 600;
      margin-top: 20px;
      width: 120px;
      text-align: center;
      padding-left: 20px;
      background-size: contain;
    }

    .text-info {
      font-size: 14px;
      color: $theme-dark;
      opacity: 0.3;

      &.checkicon {
        opacity: 1;

        .dark {
          opacity: 0.3;
        }
      }
    }

    .text-title {
      font-size: 16px;
      color: $theme-dark;
      font-weight: 600;
      margin-top: 7px;
    }

    .info {
      padding: 50px;
      text-align: left;
    }

    .appointements {
      font-size: 16px;
      color: $theme-dark;
    }
    .divider-inner {
      width: 100%;
      border: 1px solid #f2f2f2;
      margin: 30px 0;
    }

    .paid {
      color: $theme-primary;
    }

    .checkicon {
      .anticon {
        opacity: 1;

        svg {
          fill: #19946f;
        }
      }
    }

    .header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-left: 50px;
      margin-top: 20px;
    }

    .price-title {
      display: flex;
      align-items: flex-start;
      justify-content: center;
      font-size: 20px;
      font-weight: 600;

      span {
        color: $theme-dark;
        font-size: 45px;
        margin-top: -10px;
      }
    }
  }

  .divider {
    width: 100%;
    border: 1px solid #e4daff;
    margin: 0px;
  }
  .unbilled-row {
    display: flex;
    flex-wrap: wrap;
  }
}

.empty-result {
  margin-bottom: 50px !important;
}
