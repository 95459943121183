.doctor-image {
  text-align: center;
}
.doctor-image {
  .ant-upload {
    border-radius: 50% !important;

    img {
      border-radius: 50% !important;
    }
  }
}
