@import '../../../styles/variables/colors';
@import '../../../styles/variables/mixins';

.appointments-edit-component {
  padding: 20px 5px;
  .details-label {
    font-size: 12px;
    margin-bottom: 10px;
    color: #a7a7a7;
  }

  // select-custom
  .select-custom {
    .ant-select .ant-select-selector {
      height: 45px;
      align-items: center;
    }
    .label {
      margin-bottom: 10px;
      color: $theme-dark;
      font-size: 16px;
    }
    .ant-select-multiple .ant-select-selection-item {
      background: rgba($theme-primary, 0.1);
    }
  }
}
