@import '../../../styles/variables/colors';
@import '../../../styles/variables/mixins';

.add-doctor-card {
  padding: 20px 30px;
}
//   .button-section {
//     display: flex;
//     align-items: center;
//     justify-content: flex-end;

//     .button-default {
//       margin-right: 20px;
//     }
//   }

.divider {
  border-bottom: 1px solid #f0f0f0;
  margin: 30px -30px 0;
}

//   .search-section {
//     @include mobile {
//       margin-top: 30px;
//     }
//     // input {
//     //   height: 40px;
//     //   border: none;
//     //   box-sizing: border-box;
//     //   border-radius: 5px !important;
//     //   padding-left: 40px;
//     //   background-color: #f4f4f4;
//     //   font-size: 16px;
//     // }

//     // .ant-input-group {
//     //   display: flex;
//     //   flex-direction: row-reverse;
//     // }

//     .ant-btn {
//       border: none;
//       margin-top: 10px;
//       background: none;
//       margin-left: 8px;
//     }
//   }

.doctor-card-section {
  max-height: 350px;
}
//   .card-item {
//     border-bottom: 1px solid #cecece;
//     padding: 20px;
//     display: flex;
//     align-items: center;
//     justify-content: space-between;
//     transition: all 0.5s ease-in-out;

//     &:hover {
//       background: rgba(83, 46, 179, 0.05);
//     }

//     &:last-child {
//       border: none;
//     }
//   }

// .btn-add-doctor {
//   border: none;
//   padding: 5px 20px;
//   border-radius: 5px;
//   cursor: pointer;
// }

// .add-doctor {
//   display: flex;
//   align-items: center;
//   justify-content: space-between;
//   max-width: 74%;
// }

// .doctor-card {
//   display: flex;
//   align-items: center;
//   width: 230px;

//   .title {
//     margin: 0;
//     font-size: 16px;
//     font-weight: 600;
//   }
// }

// .specialization {
//   color: $theme-primary;
//   text-transform: uppercase;
// }

// .doctor-image {
//   width: 64px;
//   height: 64px;
//   border-radius: 50%;
//   overflow: hidden;
//   position: relative;
//   margin-right: 20px;

//   img {
//     @include imageCover;
//   }
// }

// .text {
//   font-size: 16px;
//   opacity: 0.7;
// }
// .appointments-sec {
//   .appointments-number {
//     font-size: 25px;
//     font-weight: 700;
//   }
// }
// }
.single-doctor-entry {
  margin: 20px 0px !important;
  .divider {
    border-bottom: 1px solid #f0f0f0;
    margin: 20px -30px 0;
  }
}

.doctors-list {
  height: 400px;
  overflow-y: scroll;
  margin: 0px -30px;
  padding: 0px 30px;
}

.title {
  font-size: 24px;
  color: $theme-dark;
  font-weight: 600;
  margin-bottom: 20px;
}
.search-add-doctors {
  .ant-input {
    font-size: 12px;
    margin-left: 4px;
    background: transparent;
  }
  .ant-input-affix-wrapper {
    padding: 4px 11px;
    border: none;
    background-color: $theme-dark-grey-background;
  }
}
