@import '../../../styles/variables/colors';

.manager-details {
  background-color: white;
  padding: 42px !important;
  border-radius: 4px;

  .label {
    font-size: 12px;
    color: $theme-grey-font;
    margin-top: 20px;
  }
  .form-label {
    font-size: 12px;
    color: #25282b;
    margin-top: 20px;
  }
  .entry {
    font-size: 15px;
    margin-top: 6px;
  }
  .ant-btn {
    margin-top: 20px;
  }
  //textArea-custom
  .textArea-custom {
    margin-top: 6px;
    textarea.ant-input {
      margin-top: 6px;
    }
  }
  // input-custom
  .input-custom {
    .ant-input {
      height: 40px;
      background: transparent;
      margin-top: 6px;
    }
  }

  .ant-select {
    margin-top: 6px;
    margin-right: 3px;

    .ant-select-selector {
      height: 40px !important;
      align-items: center !important;
    }
  }
}
