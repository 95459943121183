@import '../../variables/colors';
@import "../../variables/mixins";

.main-header {
  background: $theme-light-purple !important;
  padding: 20px 60px  0!important;
  line-height: normal;

  @include mobile-tablet-p{
    padding: 20px 20px 0 !important;
  }
}
