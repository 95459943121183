.ant-table table {
  border-spacing: 0px 10px;
  -webkit-border-horizontal-spacing: 0px;
  -webkit-border-vertical-spacing: 10px;
  td:first-child {
    border-radius: 6px 0 0 6px;
  }
  td:last-child {
    border-radius: 0px 6px 6px 0px;
  }

  .ant-table-thead > tr > th {
    background-color: transparent;
    border-bottom: none;
    border: none;
    font-size: 12px;
  }
  .ant-table-row {
    background: white;
  }
}
.ant-table {
  background: transparent;
}
