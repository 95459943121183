@import "../variables/mixins";
@import "../variables/colors";

.screen-title {
  font-size: 32px;
  margin-bottom: 20px;
  font-weight: 700;

  @include mobile {
    font-size: 24px;
    margin-bottom: 10px;
  }
  @include tablet {
    margin-bottom: 10px;
  }
}

.small {
  font-size: 12px;
}

.small-grey {
  font-size: 12px;
  color: $gray-a7;
}

.x-small-grey {
  font-size: 10px;
  color: $gray-a7;
}

.medium-grey-bold {
  font-size: 14px;
  color: $gray-a7;
  font-weight: 600;
}

.appointment-action-icon {
  width: 14px;
  margin-bottom: 4px;
}

.purple-highlight {
  font-size: 12px;
  color: $theme-purple;
}

.green-highlight {
  font-size: 12px;
  color: #27ae60;
}

.red-highlight {
  font-size: 12px;
  color: #eb5757;
}

.orange-highlight {
  font-size: 12px;
  color: #f39c12;
}

.medium-bold {
  font-size: 14px;
  font-weight: 600;
}

.small-bold {
  font-size: 12px;
  font-weight: 600;
}

.medium {
  font-size: 14px;
}

.large {
  font-size: 20px;
}

.large-bold {
  font-size: 20px;
  font-weight: 700;
}

.full-button {
  width: 100%;
  height: 56px !important;
  font-size: 20px !important;
  font-weight: 700;

  @include mobile-tablet-p {
    height: 48 !important;
    font-size: 16px;
  }
}

.custom-action-button {
  height: auto;
}

.custom-input {
  height: 56px !important;

  .ant-input {
    height: 48px !important;
  }

  @include mobile-tablet-p {
    height: 48px;

    .ant-input {
      height: 40px !important;
    }
  }
}

.custom-input-number {
  width: 100%;
}

.custom-modal {
  .ant-modal-body {
    padding: 40px !important;
  }

  .modal-children {
    .title {
      font-size: 18px;
      font-weight: 600;
    }

    .buttons {
      margin-top: 20px;
      text-align: end !important;
    }
  }
}


.custom-modal-ads {
  width: 800px !important;

  .ant-modal-body {
    padding: 40px !important;
  }

  .modal-children {
    .title {
      font-size: 18px;
      font-weight: 600;
    }

    .buttons {
      margin-top: 20px;
      text-align: end !important;
    }
  }
}

.custom-select {
  .ant-select-selector {
    height: 42px !important;
  }
}

.x-small-purple-highlight {
  font-size: 10px;
  color: $theme-purple;
}

.success {
  color: #27AE60;
  font-size: 12px;
  font-weight: 700;
}

.danger {
  color: #EB5757;
  font-size: 12px;
  font-weight: 700;
}

.warning {
  color: #F39C12;
  font-size: 12px;
  font-weight: 700;
}

.ant-picker-range {
  height: 46px;
  font-weight: 400;
  font-size: 18px;
}

.refresh-btn {
  height: 46px;
  font-weight: 600;
  font-size: 14px;
  color: $theme-primary;
}

.open {
  @include mobile-tablet-p {
    transform: translateX(0);
  }

}

.close {
  @include mobile-tablet-p {
    transform: translateX(-205px);
  }
}

.xs-mt-20{
  @include mobile-tablet-p{
    margin-top: 20px;
  }
}

.buttons-gap{
  display: flex;
  justify-content: center;
  gap: 20px;
}
.justify-end{
  justify-content: end !important;
}
.justify-between{
  justify-content: space-between;
}
.w-50{
  min-width:50px;
}