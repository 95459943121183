@import "../../../styles/variables/colors";
@import "../../../styles/variables/mixins";

.hospital-component-style {
  padding: 20px 5px;

  .info-section {
    margin-bottom: 50px;

    .text-title {
      font-size: 15px;
      font-weight: 400;
      color: $text-dark;
      opacity: 0.7;
    }

    .title {
      font-size: 18px;
      font-weight: 400;
      color: $text-dark;
    }
  }

  .input-sec {
    margin-bottom: 70px;
  }

  // input-multiple
  .input-multiple {

    .ant-input {
      height: 45px;
    }
    .label {
      margin-bottom: 10px;
      color: $theme-dark;
      font-size: 16px;
    }
    .hospital-url {
      border-radius: 0px 6px 6px 0px;
    }
    .static-url {
      border-radius: 6px 0px 0px 6px;
      text-align: center;
    }
  }

  // select-custom
  .select-custom {

    .ant-select .ant-select-selector {
      height: 45px;
      align-items: center;
    }
    .label {
      margin-bottom: 10px;
      color: $theme-dark;
      font-size: 16px;
    }
    .ant-select-multiple .ant-select-selection-item {
      background: rgba($theme-primary, 0.1);
    }
  }

  //input-number-custom
  .input-number-custom {

    .ant-input-number {
      width: 100%;
    }

    .ant-input-number .ant-input-number-input {
      height: 45px;
    }

    .label {
      margin-bottom: 10px;
      color: $theme-dark;
      font-size: 16px;
    }
  }

  //textArea-custom
  .textArea-custom {
    margin-bottom: 28px;

    .label {
      margin-bottom: 10px;
      color: $theme-dark;
      font-size: 16px;
    }
  }

  //sponsorship
  .sponsorship {
    display: flex;
    align-items: center;
    margin-bottom: 30px;

    @include mobile {
      justify-content: flex-start;
      align-items: flex-start;
      flex-direction: column;
    }

    .flex-inner {
      display: flex;
      justify-content: space-between;
      margin-left: 30px;
      width: 170px;

      @include mobile {
        margin-left: 0px;
        flex-grow: 0;
        margin-top: 20px;
      }

      .ant-checkbox-wrapper {
        margin-bottom: 0;
      }
    }

    .label {
      color: $theme-dark;
      font-size: 16px;
    }
    .ant-checkbox-inner {
      border-radius: 2px;
    }
    .ant-checkbox-wrapper {
      font-size: 16px;
    }
  }

  .open-hours {
    margin-top: 20px;

    .label {
      margin-bottom: 10px;
      color: $theme-dark;
      font-size: 16px;
    }
  }

  .info-circle {
    width: 18px;
    position: relative;
    top: -2px;
    margin-left: 5px;
  }

  .space-Number {
    margin-bottom: 72px;
  }

  .ant-input {
    font-size: 16px;
    color: $theme-dark;
  }
}

.ant-input-suffix svg {
  width: 20px; 
}
