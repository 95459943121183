.main-appointments-lineup {
    
}

.appointment-card {
    max-width: 850px;
    width: 100%;
}

.column-label {
    font-weight: bold;
}
.column-appointment-value{
    margin-left: 8px;
}
.appointment-pagination{
    text-align: center;
    margin-top: 20px;
}