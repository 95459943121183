@import '../../styles/variables/colors';

.stats-card {
  width: 100%;
  min-width: 250px;
  height: 100px;
  border-radius: 10px !important;
  box-shadow: 0px 16px 34.6667px rgba(16, 30, 115, 0.06);
  .container {
    flex-direction: row;
    display: flex;
    align-items: center;
  }
  .icon-container {
   // flex: 1;
   padding-right: 15px;
  }

  .text-container {
    flex: 2;
    display: flex;
    flex-direction: column;

    .data {
      display: flex;
     // flex-direction: row;
     align-items: center;
     justify-content: space-between;
    }
  }
}
.percent-text {
  //width: 50px;
  
}
.appointments-link {
  text-align: center;
  color: $theme-purple;
}

.refresh-btn{
  height: 46px;
}