@import "../../../styles/variables/colors";
@import "../../../styles/variables/mixins";

.landing-edit-component {
  padding: 20px 5px;
  .title {
    font-size: 20px;
    color: $theme-dark;
    margin-bottom: 20px;
  }

  .info-section {
    margin-bottom: 50px;

    .text-title {
      font-size: 15px;
      font-weight: 400;
      color: $text-dark;
      opacity: 0.7;
    }

    .title {
      font-size: 18px;
      font-weight: 400;
      color: $text-dark;
    }
  }

  .input-sec {
    margin-bottom: 70px;
  }

  // input-multiple
  .input-multiple {
    .ant-input {
      height: 45px;
    }
    .label {
      margin-bottom: 10px;
      color: $theme-dark;
      font-size: 16px;
    }
    .hospital-url {
      border-radius: 0px 6px 6px 0px;
    }
    .static-url {
      border-radius: 6px 0px 0px 6px;
      text-align: center;
    }
  }

  // select-custom
  .select-custom {
    .ant-select .ant-select-selector {
      height: 45px;
      align-items: center;
    }
    .label {
      margin-bottom: 10px;
      color: $theme-dark;
      font-size: 16px;
    }
    .ant-select-multiple .ant-select-selection-item {
      background: rgba($theme-primary, 0.1);
    }
  }

  //input-number-custom
  .input-number-custom {
    .ant-input-number {
      width: 100%;
    }

    .ant-input-number .ant-input-number-input {
      height: 45px;
    }

    .label {
      margin-bottom: 10px;
      color: $theme-dark;
      font-size: 16px;
    }
  }

  //textArea-custom
  .textArea-custom {
    margin-bottom: 28px;

    .label {
      margin-bottom: 10px;
      color: $theme-dark;
      font-size: 16px;
    }
  }

  //sponsorship
  .sponsorship {
    display: flex;
    align-items: center;
    margin-bottom: 30px;

    @include mobile {
      justify-content: flex-start;
      align-items: flex-start;
      flex-direction: column;
    }

    .flex-inner {
      display: flex;
      justify-content: space-between;
      margin-left: 30px;
      width: 170px;

      @include mobile {
        margin-left: 0px;
        flex-grow: 0;
        margin-top: 20px;
      }

      .ant-checkbox-wrapper {
        margin-bottom: 0;
      }
    }

    .label {
      color: $theme-dark;
      font-size: 16px;
    }
    .ant-checkbox-inner {
      border-radius: 2px;
    }
    .ant-checkbox-wrapper {
      font-size: 16px;
    }
  }

  .open-hours {
    margin-top: 20px;

    .label {
      margin-bottom: 10px;
      color: $theme-dark;
      font-size: 16px;
    }
  }

  .info-circle {
    width: 18px;
    position: relative;
    top: -2px;
    margin-left: 5px;
  }

  .space-Number {
    margin-bottom: 72px;
  }

  .ant-input {
    font-size: 16px;
    color: $theme-dark;
  }

  .doctor-section {
    margin-bottom: 50px;

    .btn-add-doctor {
      border: none;
      padding: 5px 20px;
      border-radius: 5px;
      cursor: pointer;
    }

    .add-doctor {
      display: flex;
      align-items: center;
      justify-content: space-between;
      max-width: 74%;
    }

    .doctor-card {
      border-radius: 5px;
      border: 1px solid #cecece;
      padding: 20px;
      display: flex;
      align-items: center;

      .title {
        margin: 0;
        font-size: 16px;
        font-weight: 600;
      }
    }

    .specialization {
      color: $theme-primary;
      text-transform: uppercase;
    }

    .doctor-image {
      width: 64px;
      height: 64px;
      border-radius: 50%;
      overflow: hidden;
      position: relative;
      margin-right: 20px;

      img {
        @include imageCover;
      }
    }
  }

  .avatar-upload {
    margin-top: 20px;

    .label {
      margin-bottom: 10px;
      color: $theme-dark;
      font-size: 16px;
    }
  }

  .brand-colors {
    .label {
      margin-bottom: 20px;
      color: $theme-dark;
      font-size: 16px;
    }

    .text {
      margin-top: 10px;
    }
  }

  // color box
  .colorbox {
    display: flex;

    .box {
      border-radius: 4px;
      width: 36px;
      height: 36px;
    }

    .text {
      font-size: 14px;
      color: #797979;
    }

    .item {
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      margin-right: 40px;
    }
  }

  // add-color
  .add-color {
    display: flex;
    align-items: center;

    .box {
      border-radius: 4px;
      width: 36px;
      height: 36px;
      background-color: #f2eeff;
      font-size: 25px;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .text {
      font-size: 14px;
      color: #797979;
    }

    .item {
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      margin-right: 40px;
    }
  }

  .hospital-image-upload {
    .upload-list-inline {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
    }

    .ant-upload-list-item-thumbnail,
    .ant-upload-list-item-image {
      width: 100%;
      height: 100%;
    }

    .ant-upload-list-item {
      margin-top: 0;
    }

    .ant-upload-list {
      max-width: 40%;
    }

    .ant-upload-list-item {
      min-height: 400px;
      position: relative;
    }

    .ant-upload-list-item-card-actions {
      position: absolute;
      top: 20px;
      right: 20px;
    }
  }
}
