@import '../../styles/variables/colors';

.departments-screen {
  .ant-tabs-tab-active {
    border-bottom: none !important;
    border-top-color: #532eb3 !important;
    border-left: none !important;
    border-right: none !important;
    border-width: medium !important;
    font-weight: 700;
  }
  .ant-tabs-tab-btn {
    color: $textColor !important;
    transition: none !important;
  }
  .ant-tabs-tab {
    margin: 0px !important;
    border-radius: 0px !important;
    transition: none !important;
  }

  .department-sub-tabs {
    .ant-tabs-tab-active {
      border-bottom: none !important;
      border-top-color: #532eb3 !important;
      border-left: none !important;
      border-right: none !important;
      border-width: medium !important;
      font-weight: 400;
    }
    .ant-tabs-tab-btn {
      color: $textColor !important;
      transition: all 0.3s;
    }
    .ant-tabs-tab {
      margin-right: 32px !important;
      border-radius: 0px !important;
      transition: none !important;
    }
    .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
      color: #532eb3 !important;
    }
  }

  .departments-container {
    background: white;
    border-radius: 6px;
  }
  .departments-tabpane {
    padding: 30px;
  }

  .department-name {
    font-size: 20px;
    margin-bottom: 4px;
  }
  .ant-drawer-body {
    background: white !important;
  }
  .search-add-doctors {
    .ant-input {
      font-size: 12px;
      margin-left: 4px;
      background: transparent;
    }
    .ant-input-affix-wrapper {
      padding: 4px 11px;
      border: none;
      background-color: $theme-dark-grey-background;
    }
  }
  .ant-table table {
    td {
      border-top: thin solid #dbdde0;
      border-bottom: thin solid #dbdde0;
    }

    td:first-child {
      border-left: thin solid #dbdde0;
    }

    td:last-child {
      border-right: thin solid #dbdde0;
    }
  }
}
