@import '../../styles/variables/colors';

.search-doctor {
  padding: 15px;
  background: white;
  border-radius: 4px;
  margin-bottom: 20px;

  .ant-input {
    font-size: 12px;
    margin-left: 4px;
    background: transparent;
  }

  .ant-input-affix-wrapper {
    padding: 4px 11px;
    border: none;
    background-color: $theme-dark-grey-background;
  }

  //selector

  .ant-select-selector {
    font-size: 12px !important;
    align-items: center;
    min-width: 110px !important;
    text-align: center;
  }

  //radio-group
  .ant-radio-button-wrapper {
    font-size: 12px;
  }

  //date-picker
  .ant-picker {
    width: 240px;

    .ant-picker-input {
      padding: 2px;

      input {
        font-size: 12px !important;
      }
    }
  }
}

.doctor-details-drawer {
  .ant-drawer-close {
    padding: 10px !important;
  }

  .ant-drawer-body {
    background-color: $theme-grey-background;
  }

  .drawer-title {
    font-size: 20px;
    margin-bottom: 10px;
    font-weight: 600;
  }
}

.link-modal {
  .visit-button {
    margin-top: 20px;
    text-align: end;
  }

  .ant-row {
    align-items: baseline;
  }
}