// variables
@import "../../../styles/variables/colors";
@import "../../../styles/variables/mixins";

.modal-primary-style {
  .ant-modal-header {
    border: none;
    border-radius: 0;
  }

  .ant-modal-footer {
    padding: 30px 20px;

    .ant-btn {
      min-width: 100px;
      height: 40px;
    }
  }

  .ant-modal-content {
    
  }

  .modal-title {
    font-weight: 600;
    font-size: 35px;
    letter-spacing: 0.01em;
    color: $theme-dark;
    margin-bottom: 40px;
  }

  .ant-modal-close {
    position: absolute;
    top: 30px;
    transform: scale(1.4);
    right: 60px;
    display: none;

    @include mobile {
      right: 0px;
      top: 0px;
    }
  }

  &.ant-modal {
    top: 35px;
  }

  .select-area {
    display: flex;
    margin-bottom: 30px;

    .ant-checkbox-wrapper {
      min-width: 150px;

      @include mobile {
        min-width: 120px;
      }
    }
  }

  .text-info {
    margin-bottom: 10px;
    font-size: 17px;
    color: $theme-dark;

    span {
      color: $theme-danger;
    }
  }

  .input-custom {
    margin-bottom: 20px;
  }

  .button-primary {
    margin-top: 60px;
  }

  .ant-modal-body {
    padding: 0;

    @include mobile {
      padding: 20px 0;
    }
  }
}

.ant-modal-mask {
  background: rgba($black, 0.6);
  backdrop-filter: blur(4px);
}
