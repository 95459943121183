@import '../../variables/colors';
@import '../../variables/mixins';

.main-content {
  padding: 20px 25px;
  background-color: $theme-grey-background;
  min-height: calc(100vh - 64px);

  @include mobile-tablet-p{
    padding: 0 20px;
  }
}
