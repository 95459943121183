.notifications-drawer {
  padding: 20px;

  .heading {
    margin-bottom: 30px;
  }

  .notif-date {
    margin: 20px 0px;
    font-weight: 700;
  }
  .see-more-button {
    margin-top: 30px;
    text-align: center;
  }
}
