@import '../../../styles/variables/colors';

.details {
  .ant-divider-horizontal {
    margin: 15px 0px;
  }

  .doctor-details {
    background-color: white;
    padding: 42px !important;
    border-radius: 4px;
    .doctor-image {
      text-align: center;
      margin-bottom: 20px;
    }

    .ant-btn {
      margin-top: 20px;
    }
    //textArea-custom
    .textArea-custom {
      margin-top: 6px;
      textarea.ant-input {
        margin-top: 6px;
      }
    }
    // input-custom
    .input-custom {
      .ant-input {
        height: 40px;
        background: transparent;
        margin-top: 6px;
      }
    }

    // select-custom
    .select-custom {
      .ant-select {
        margin-top: 6px;
        width: 100%;
        .ant-select-selector {
          height: 40px;
          align-items: center;
        }
      }

      .ant-select-multiple .ant-select-selection-item {
        background: rgba($theme-primary, 0.1);
      }
    }
  }
  .doctor-appointments {
    background-color: white;
    padding: 42px !important;
    border-radius: 4px;
    margin-top: 30px;
    .appointments-link {
      text-align: center;
      color: $theme-purple;
    }
    .single-appointment {
      margin-top: 20px;
    }
  }
  .doctor-availability {
    background-color: white;
    padding: 42px !important;
    border-radius: 4px;

    .availability-tabs {
      .current-day {
        text-align: center;
      }

      .available-slots {
        color: $theme-green;
        font-size: 12px;
      }
      .ant-tabs-tab .ant-tabs-tab-active {
        color: #25282b !important;
      }
      .ant-tabs-tab:hover {
        color: #25282b !important;
      }
    }
    .ant-tabs > .ant-tabs-nav .ant-tabs-nav-more,
    .ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-more {
      display: none;
    }
  }
}
