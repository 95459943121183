@import url('https://fonts.googleapis.com/css2?family=Lato:wght@100;300;400;700;900&display=swap');

// variables
@import './variables/colors';
@import './variables/mixins';

// theme styles files
@import './xtras';
// @import './fonts';

// theme components
@import './components/layout/table';

//theme content
@import './components/layout/content';

//theme header
@import './components/layout/header';

//theme drawer
@import './components/layout/drawer';

//theme pagination
@import './components/layout/pagination';

//theme custom fonts sizes and headings
@import './components/custom';

// custom css
* {
  font-family: 'Lato', sans-serif !important;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

html,
body {
  height: 100%;
  padding: 0;
  margin: 0;
  scroll-behavior: smooth;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#root {
  // height: 100%;
}

.app-container {
  position: relative;
  // height: 100%;
  // overflow-x: hidden;

  overflow-x: hidden;
}

// Typography Style
.title1,
.title2,
.title3,
.title4,
.title5,
.title6 {
  margin: 0;
}

.title1 {
  font-size: 2rem;
  line-height: 39px;
  font-weight: bold;
}

.title2 {
  font-size: 1.75rem;
  line-height: 39px;
  font-weight: 600;
}

.title3 {
  font-size: 1.5rem;
  line-height: 32px;
  font-weight: 600;

  // On mobile
  @include mobile {
    font-size: 1.3rem;
  }
}

.title4 {
  font-size: 1.3rem;
  line-height: 39px;
  font-weight: 600;
}

.title5 {
  font-size: 1rem;
  line-height: 20px;
  font-weight: 600;
}

.title6 {
  font-size: 0.875rem;
  line-height: 20px;
  font-weight: 600;
}

// carousel foucs
.outline-none {
  &:focus {
    outline: none;
  }

  .flickity-viewport {
    &:focus {
      outline: none;
    }
  }
}

// Join us button style
.join-us-btn {
  width: 230px;
  height: 230px;
  background-image: linear-gradient(to bottom, #000081, #000030);
  border-radius: 50%;
  cursor: pointer;
  color: $white;
  font-size: 28px;
  font-weight: bold;
  border: none;
}

.join-button-section {
  display: flex;
  align-items: center;
  justify-content: flex-end;

  @include mobile {
    justify-content: center;
  }
}

// Container size
// .container {
//   @media (min-width: 1600px) {
//     max-width: 95%;
//    }
// }

// checkbox Style
.checkbox-inner-style {
  .ant-checkbox-wrapper {
    margin-bottom: 20px;
    margin-left: 0;
    display: flex;
    align-items: center;
  }

  .ant-checkbox-inner {
    width: 22px;
    height: 22px;
    background: $gray-light;
    border-radius: 2px;
    border: none;
  }

  .ant-checkbox-checked {
    .ant-checkbox-inner {
      background-color: $theme-primary;
    }
  }

  .ant-checkbox {
    position: relative;
    top: 2px;
    margin-right: 7px;
  }
}

.ant-checkbox-inner::after {
  transform: rotate(45deg) scale(1) translate(-30%, -70%) !important;
}

// Radio Style
.ant-radio-group {
  height: 46px;

  .ant-radio-wrapper {
    margin-bottom: 20px;
  }

  .ant-radio-button-wrapper-checked {
    color: #fff;
  }

  .ant-radio-button-wrapper {
    padding: 7px 15px;
    height: 100%;
    width: 100px;
    text-align: center;
    font-size: 14px;
    font-weight: 700;
    color: #CACCCF;

    @include mobile-tablet-p{
      width: 80px;
    }
  }

  .ant-radio-inner {
    width: 22px;
    height: 22px;
    background: $gray-light;
    border-radius: 50%;
    border: none;
    position: relative;
    top: 4px;

    &::after {
      top: 5px;
      left: 5px;
      width: 12px;
      height: 12px;
    }
  }
}

// error color style
.text-error {
  color: $theme-danger;
  margin-left: 5px;
}

// dot setup
.dot-setup {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 40px;

  .dot-style {
    width: 8px;
    height: 8px;
    display: block;
    border-radius: 50%;
    background: rgba($theme-primary, 0.2);
    margin-right: 26px;

    &.dot-active {
      background: rgba($theme-primary, 1);
    }

    &:last-child {
      margin-right: 0;
    }
  }
}

// Datepicker Style
.date-picker {
  margin-top: 20px;

  .ant-picker {
    width: 100%;
    min-height: 45px;
  }

  .label {
    margin-bottom: 10px;
    color: $theme-dark;
    font-size: 16px;
  }
}

// disabled button style
.ant-btn-primary[disabled] {
  background-color: rgba($theme-primary, 0.4) !important;
  color: $white !important;
}