.slot-time {
  display: flex;
  flex-wrap: wrap;
}
.single-time {
  border: 1px solid #cecece;
  border-radius: 4px;
  padding: 4px 0px;
  text-align: center;
  min-width: 56px;
  margin: 10px 10px 10px 0px;
  cursor: pointer;
}
