@import '../../variables/colors';
@import '../../variables/mixins';

.main-drawer {

  // .ant-drawer-body {
  //   background-color: $theme-grey-background;
  // }
  .drawer-title {
    font-size: 20px;
    margin-bottom: 10px;
    font-weight: 600;
  }
}

.add-drawer-content {
  padding: 30px;
}

.add-drawer-form {
  margin-top: 20px;
}

.hospital-details-drawer {
  .ant-drawer-content-wrapper {
    width: 70% !important;

    @include mobile-tablet-p {
      width: 100% !important;
    }
  }
}
.drawer-50{
  .ant-drawer-content-wrapper {
    width: 50% !important;

    @include mobile-tablet-p {
      width: 100% !important;
    }
  }
}