@import '../../styles/variables/colors';

.search-appointment {
  padding: 15px;
  background: white;
  border-radius: 4px;
  margin-bottom: 20px;

  .ant-input-search > .ant-input-group > .ant-input-group-addon:last-child {
    visibility: hidden;
  }

  .ant-input {
    font-size: 12px;
    margin-left: 4px;
    background: transparent;
  }
  .ant-input-affix-wrapper {
    padding: 4px 11px;
    border: none;
    background-color: $theme-dark-grey-background;
  }

  //selector

  .ant-select-selector {
    font-size: 12px !important;
    align-items: center;
    min-width: 110px !important;
  }

  //radio-group
  .ant-radio-button-wrapper {
    font-size: 12px;
  }

  //date-picker
  .ant-picker {
    width: 240px;
    .ant-picker-input {
      padding: 2px;
      input {
        font-size: 12px !important;
      }
    }
  }
}
.display-none {
  display: none;
}
