@import '../../../styles/variables/colors';

.color-div {
  border-radius: 4px;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.sub-title {
  font-size: 20px;
  margin: 20px 0px;
  font-weight: 600;
}
.details-drawer-content {
  padding: 0px 30px;
}
.details-drawer-title {
  font-size: 20px;
  margin-bottom: 30px;
  font-weight: 600;
}
.details-label {
  font-size: 12px;
  margin-bottom: 10px;
  color: #a7a7a7;
}
.hospital-logo {
  display: flex;
  border-radius: 6px;
  border: 1px solid;
  border-color: $theme-primary;
  padding: 8px;
  align-items: center;
  justify-content: center;
  width: 160px;
}
