.done-modal {
  .ant-modal-body {
    padding: 40px !important;
    text-align: center;
    color: linear-gradient(
      178.18deg,
      #34c981 -13.56%,
      #532eb3 158.3%
    ) !important;
    font-size: 18px;
    font-weight: 600;
  }
  .green-text {
    margin-top: 10px;
  }
}
