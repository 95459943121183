@import "../../styles/variables/colors";
@import "../../styles/variables/mixins";

.hospital-detail-style {

  // title Large style
  .title-lg {
    font-size: 28px;
    line-height: 46px;
    letter-spacing: 0.2px;
    font-weight: bold;
    margin-bottom: 20px;
  }

  .tab-section {
    .ant-card-body {
      padding:10px 0 0;
    }
    .ant-tabs-tab-btn {
      font-size: 18px;
    }

    .ant-tabs-nav {
      margin-bottom: 0;
    }

    .ant-tabs-nav-wrap {
      padding: 0px 20px;
    }

    .ant-tabs-content-holder {
      padding: 20px;
    }
    .ant-tabs-tabpane{
      flex : unset;
    }
  }

  .button-section {
    display: flex;
    align-items: center;

    .button-style {
      margin-right: 20px;
    }

    .ant-btn {
      min-width: 150px;
    }
  }

}
