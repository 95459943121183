@import '../../../styles/variables/colors';
@import '../../../styles/variables/mixins';

.ant-layout-sider {
  min-height: 100vh;
  font-weight: 700;
  background-color: white !important;
  box-shadow: 5.33333px 0px 21.3333px rgba(16, 30, 115, 0.08);
  position: relative;

  @include mobile-tablet-p {
    position: absolute !important;
    z-index: 99;
  }

  .logo {
    margin: 20px 20px 40px 20px;
    display: flex;
    justify-content: space-between;
    align-items: end;

    img {
      width: 120px;
    }
  }

  .hospital-selector {
    width: -webkit-fill-available;
    margin: 0px 20px 40px 20px;

    .ant-select-selector {
      border: none !important;
      background: $theme-light-purple !important;
      padding: 4px 11px !important;
      height: 40px !important;
    }
  }

  .hospital-identity {
    display: flex;
    background: $theme-light-purple;
    padding: 4px 11px;
    height: 40px;
    margin: 0px 20px 40px 20px;
    border-radius: 6px;
    justify-content: space-evenly;
    align-items: center;
  }
}

.hospital-option {
  display: flex;
  justify-content: space-evenly;
}