@import '../../styles/variables/colors';

.day {
  border-radius: 50%;
  text-align: center;
  padding: 1px;
  background-color: $theme-info;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  cursor: pointer;
}
.singleTime {
  margin-bottom: 20px;
  background-color: $gray-f9;
  border-radius: 6px;
  height: 40px;
  min-width: 380px;
}
