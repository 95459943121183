// ######################################## //
// ## COLORS.SCSS ## //
// ######################################## //

// Black (Gray) Color Shades
$black: #000000;
$gray-1: #111111;
$gray-2: #222222;
$gray-3: #333333;
$gray-4: #444444;
$gray-5: #555555;
$gray-6: #666666;
$gray-7: #777777;
$gray-8: #888888;
$gray-9: #999999;
$gray-a: #aaaaaa;
$gray-b: #bbbbbb;
$gray-c: #cccccc;
$gray-d: #dddddd;
$gray-e: #eeeeee;
$white: #ffffff;
$gray-f9: #f9f9f9;
$gray-a7: #a7a7a7;
$gray-dark: #c9cdd8;
$text-dark: #25282b;
$gray-light: #efefef;
$label-text: #6f7588;
$border-color: #e1e3ea;
$arrow-color: #a8b1ce;
$textColor: #25282b;
// Color Shades (Theme Based - Xclusive)
$theme-primary: #532eb3;
$theme-secondary: #0f1938;
$theme-success: #037e56;
$theme-dark: #25282b;
$theme-info: #9c6dff;
$theme-warning: #f59e0b;
$theme-green: #36942f;
$theme-purple: #532eb3;
$theme-warning-light: #fef6e0;
$theme-danger: #e24c4b;
$theme-yellow: #ea9d04;
$theme-cyan: #35d2cc;
$theme-pink: #c83375;
$theme-link: #004b8f;

$theme-light-purple: #f6f8fb;
$theme-grey-background: #faf8ff;
$theme-dark-grey-background: #f4f4f4;
$theme-grey-font: #a7a7a7;
$theme-grey-border: #cececece;
