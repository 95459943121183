.availability-screen {
  .custom-select {
    margin-bottom: 30px;
    .ant-select-selector {
      width: 150px !important;
    }
  }

  .availability-content {
    background: white;
    border-radius: 8px;
    padding: 30px;

    .form-title {
      font-size: 18px;
    }
    .form-label {
      margin: 20px 0px;
    }
    .avaiability-form {
      width: 360px;
    }
    .ant-radio-group-large .ant-radio-button-wrapper {
      font-size: 14px !important;
      margin-bottom: 40px;
    }
  }
}

.events {
  margin: 0;
  padding: 0;
  list-style: none;
  word-wrap: break-word;
}
.events .ant-badge-status {
  width: 100%;
  overflow: hidden;
  font-size: 12px;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.notes-month {
  font-size: 28px;
  text-align: center;
}
.notes-month section {
  font-size: 28px;
}
